import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Template } from '../components/mainContainer'
import { Seo } from '../components/seo'
import { ItemGrid, Item, FancyHeader1 } from '../components/common'
import * as utils from '../utils'

const categoryFilter = (cats, slug) => {
  return cats.filter(({ node }) =>
    node.contentfulparent &&
      node.contentfulparent.slug === slug)
}

const CollectionTemplate = props => {
  const category = get(
    props, 'data.contentfulCategory')
  const subcategories = categoryFilter(
    get(props, 'data.allContentfulCategory.edges', []),
    props.pageContext.slug)
  const collections = get(
    props, 'data.allContentfulCollection.edges', [])
  const products = get(
    props, 'data.allContentfulProduct.edges', [])

  const ls = utils.join(collections, products)
  const title = category.name
  const path = props.path === '/*'
    ? `/${ category.slug }/`
    : props.path
  return (
    <Template>
      <Seo title={title} location={props.location} />
      <FancyHeader1>{ category.name }</FancyHeader1>
      {subcategories && (
        <ItemGrid justifyCenter>
          {subcategories.map(s => {
            const subcategory = s.node
            const url = `${ path }${ subcategory.slug }/`
            return (
              <Item
                mobileDivisor={2}
                url={url}
                key={url}
                title={subcategory.name}
                image={get(subcategory, 'image')} />
            )
          })}
        </ItemGrid>
      )}
      {ls && (
        <ItemGrid justifyCenter>
          {ls.map(({ type, obj }) => {
            if (type === 'collection') {
              const item = obj.node
              const url = `/collection/${ item.slug }/`
              const title = item.displayTitle ? item.title : null
              return (
                <Item
                  category={category.slug}
                  key={url}
                  url={url}
                  title={title}
                  comingSoon={item.comingSoon}
                  image={get(item, 'images[0]')}
                />
              )
            } else if (type === 'product') {
              const item = obj.node
              const url = `/product/${ item.slug }/`
              const title = item.displayTitle ? item.title : null
              return (
                <Item
                  key={url}
                  url={url}
                  mobileDivisor={2}
                  title={title}
                  comingSoon={item.comingSoon}
                  image={get(item, 'images[0]')}
                />
              )
            }
            return null
          })}
        </ItemGrid>
      )}
    </Template>
  )
}

export default CollectionTemplate

export const pageQuery = graphql`
  query SubOutfitBySlug($slug: String!) {
    contentfulCategory(slug: { eq: $slug }) {
      id
      name
      slug
      product {
        title
        slug
      }
    }
    allContentfulCollection(filter: {
        categories: {elemMatch:{slug: {in: [$slug]}}}
      }
      sort: {fields:[publishedDate], order:DESC}
    ) {
      edges {
        node {
          id
          slug
          title
          comingSoon
          displayTitle
          publishedDate
          images {
            title
            fluid(maxHeight: 300) {
             ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulProduct(filter: {
        category: {elemMatch:{slug: {in: [$slug]}}}
      }
      sort: {fields:[publishedDate], order:DESC}
    ) {
      edges {
        node {
          id
          slug
          title
          comingSoon
          displayTitle
          publishedDate
          images {
            title
            fluid(maxHeight: 300) {
             ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulCategory(
      # filter: {
      #   contentfulparent:{ slug: { eq: $slug }}
      # }
      sort: {
        fields:[order], order:ASC
      }) {
      edges {
        node {
          id
          name
          slug
          contentfulparent {
            slug
          }
          image {
            title
            fluid(maxHeight: 300) {
             ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
